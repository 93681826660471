.ScrollContainer {
  position: relative;
}
.ScrollIndicator {
  position: absolute;
  background: url("../../../public/mouse.png");
  right: -48px;
  bottom: 0px;
  opacity: 0;
  width: 48px;
  height: 48px;
  background-size: cover;
  transition: opacity 0.25s ease-in-out;

  &.Visible {
    opacity: 0.3;
  }

  @media (max-width: 640px) {
    display: none;
  }
}
