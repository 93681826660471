.label {
  display: inline-block;
  width: 180px;
  @media  (max-width: 640px) {
    display: block;
    margin-top: 32px;
  }
}

.contactItems {
  margin-top: 64px;
  @media  (max-width: 640px) {
    margin-top: 32px;
  }
}