.columned {
  display: flex;
  font-size: 16px;

  @media  (max-width: 1200px) {
    font-size: 14px;
  }

  @media  (max-width: 640px) {
    display: block;
  }

  div {
    flex: 1;
  }

  .dateblock {
    font-weight: 700;
    flex: 0 0 130px;
  }

  .titleblock {
    font-weight: 700;
    flex: 0 0 180px;
    @media  (max-width: 1200px) {
      font-size: 14px;
      flex: 0 0 120px;
    }
  }

  .company {
    font-weight: 700;
    display: block;
  }

  .description {
    font-size: 12px;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    margin-left: 20px;
  }
}

.separator {
  width: 50%;
  height: 0px;
  margin: 32px auto 32px 0;
  border-bottom:3px dotted rgba(255,255,255,0.25);

  :global(body.js-dark) & {
    border-color: rgba(0,0,0,0.25);
  }
}
