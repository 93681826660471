.List {
  list-style-position: outside;
  list-style-type: square;
  margin-left: 32px;
  line-height: 200%;
}

.ListItem {
  font-size: 22px;
  margin-bottom: 16px;

  @media  (max-width: 640px) {
    font-size: 20px;
  }

  &:last-child {
    margin-bottom: 48px;
  }
}

.Me {
  width: 185px;
  height: 185px;

  @media  (max-width: 960px) {
    width: 100px;
    height: 100px;
  }
}

.AboutHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;
  align-items: flex-end;

  p {
    margin-bottom: 0;
  }
}