.ScrollWrapper {
  height: calc(100vh - 64px - 64px);
  padding-right: 32px;
  @media  (max-width: 1200px) {
    height: calc(100vh - 32px - 32px);
  }
  @media  (max-width: 640px) {
    height: 100%;
    padding-right: 0;
  }
}

