.nav {
  flex: 0;
  text-align: right;

  padding-bottom: 32px;

  li {
    height: 38px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 24px;
  }

  @media (max-width: 640px) {
    li {
      height: 32px;
      font-size: 16px;
    }
    padding-bottom: 16px;
    // border-bottom: 1px solid white;
    // margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 16px;
    :global(body.js-dark) & {
      border-color: #333;
    }
  }
}

.navLink {
  display: block;
  text-decoration: none;
  color: white;
  transition: color 0.12s linear;
  letter-spacing: 1px;
  transition: margin-right 0.25s ease-in-out;
  position: relative;

  &.active {
    margin-right: -8px;
    @media (max-width: 640px) {
      margin-right: 16px;
      &::after {
        position: absolute;
        content: "\2022";
        right: -16px;
      }
    }
  }

  &.external::after {
    font-family: "fontello";
    content: "\f08e";
    position: absolute;
    left: -32px;
    // left: calc(100% + 6px);
    top: 6px;
    font-size: 16px;
    transition: opacity 0.25s ease-in-out;
    opacity: 0.2;

    @media (max-width: 640px) {
      position: static;
      font-size: 12px;
      opacity: 1;
      content: " \f08e";
      //content: '';
    }
  }

  &.external:hover::after {
    opacity: 1;
  }
}
