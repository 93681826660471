.siteWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;

  @media (max-width: 640px) {
    flex-direction: column;
  }
}

.columnColorOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(74, 29, 29, 0.95);
  mix-blend-mode: color;
  transition: background-color 0.45s linear;

  &.shift-0 {
    background-color: rgba(74, 29, 29, 0.95);
  }
  &.shift-1 {
    background-color: rgba(40, 29, 74, 0.85);
  }
  &.shift-2 {
    background-color: rgba(59, 81, 17, 0.85);
  }
  &.shift-4 {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

.leftColumn {
  isolation: isolate;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  color: white;
  background: url("../../../public/leftcolbggray.jpg");
  background-size: cover;
  background-position: top right;
}

.rightColumn {
  flex: 2;
}

.header {
  flex: 1;
  position: relative;
  margin-left: 32px;
  text-align: right;
  padding-top: 32px;
  transition: color 0.28s linear;
  font-size: 16px;

  .name {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .job {
    margin-bottom: 6px;
  }

  .small {
    font-size: 12px;
  }

  @media (max-width: 640px) {
    font-size: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid white;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 16px;
    :global(body.js-dark) & {
      border-color: #333;
    }
    .name {
      font-size: 16px;
    }
  }
}

.contentWrapper {
  margin: 64px 224px 64px 128px;

  position: relative;
  height: 100vh;
  color: #333;
  transition: color 0.25s linear;

  @media (max-width: 1600px) {
    margin: 64px 64px 64px 128px;
  }
  @media (max-width: 1200px) {
    margin: 32px 32px 32px 64px;
  }
  @media (max-width: 640px) {
    margin: 32px 16px 48px 16px;
    height: auto;
  }

  p,
  section {
    font-size: 16px;
    margin-bottom: 32px;
    line-height: 200%;
    a {
      font-weight: 700;
    }
  }

  @media (max-width: 640px) {
    section {
      font-size: 12px;
    }
  }

  strong {
    font-weight: 700;
  }

  ul:global(.listing) {
    list-style: disc;
    list-style-position: outside;
    margin-left: 20px;
    & li {
      display: list-item;
      margin-bottom: 12px;
    }
    & :global(.source) {
      font-size: 10px;
      line-height: 10px;
    }
  }

  h2 {
    font-size: 36px;
    margin-bottom: 24px;
  }

  a:link,
  a:visited,
  a:active {
    // color: white;
    color: #333;
    text-decoration: none;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.4);
    transition: border-bottom-color 0.2s linear;
    &:hover {
      border-bottom: 1px dotted rgba(255, 255, 255, 1);
    }
    :global(body.js-dark) & {
      // color: #333;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.4);
      &:hover {
        border-bottom: 1px dotted rgba(0, 0, 0, 1);
      }
    }
  }

  :global(.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle) {
    background: rgba(white, 0.25);
  }

  :global(.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle) {
    background: rgba(white, 0.25);
    &:global(.active) {
      background: rgba(white, 0.25);
    }
    transition: background 0.25s linear;

    :global(body.js-dark) & {
      background: rgba(#333, 0.25);
    }
  }
}
